import React, { useState } from "react";
import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";
import FormContactSub from "../formSubsidioAlimentacion/formSubsidioAlimentacion";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { OptimizeImage } from "../../../shared/utils/stringUtilities";
import "./bodyContactSub.scss";

interface IBodyContact {
   nodes: {
      tituloPrincipal: string;
      descripcion: { json: any };
      tituloFormulario: string;
      descripcionFormulario: { json: any };
      tituloUbicacion: string;
      tituloBoton: string;
      redireccionBoton: string;
      referenciaUbicaciones: Array<{
         titulo: string;
         tituloUbicacion: string;
         localizacion: {
            lat: number;
            lon: number;
         };
      }>;
      referenciaPreguntasFrecuentes: Array<ReferenciaContenido>;
      titulosFormulario: { json: any };
      imagenFormulario: any;
   };
}

const Text = (children: any, classname: string) => (
   <p className={classname}>{children}</p>
);

const Span = (children: any) => <span>{children}</span>;

const options_subtitle = {
   renderNode: {
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) =>
         Text(children, "o-contact__header-subtitle"),
   },
};

const options_message = {
   renderNode: {
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => Span(children),
   },
};

const BodyContactSub = (props: IBodyContact) => {
   const [isUser, setIsUser] = useState(false);
   return (
      <div className="o-contact">
         <Breadcrumb content={`Inicio / ${props.nodes[0].tituloPrincipal}`} />

         <div className={`o-contact__header`}>
            <h1 className="o-contact__header-title title-primary">
               {props.nodes[0].tituloPrincipal}
            </h1>
            {props.nodes[0].descripcion.json.content &&
               documentToReactComponents(
                  props.nodes[0].descripcion.json,
                  options_subtitle
               )}
            
         </div>

         <div className={`o-contact__cont`}>
            <div className="o-contact__cont-form">
               <FormContactSub
                  {...props.nodes[0].titulosFormulario}
                  isUser={isUser}
                  setIsUser={setIsUser}
               />
            </div>
            <div className={"o-contact__cont-img hide-on-mobile"}>
               <img
                  loading="lazy"
                  src={
                     props.nodes[0].imagenFormulario &&
                     OptimizeImage(
                        props.nodes[0].imagenFormulario.file.url,
                        props.nodes[0].imagenFormulario.file.contentType,
                        768
                     )
                  }
                  alt="Imagen contacto"
               />
            </div>
         </div>
      </div>
   );
};

export default BodyContactSub;
