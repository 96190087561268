import React, { useState, useEffect, useContext } from "react";
import Input from "../../atoms/input/input";
import InputFiles from "../../atoms/input-files/inputFiles";
import Button from "../../atoms/button/button";
import useLoginGigya from "../../../shared/hooks/useLoginGigya";
import Loading from "../../atoms/loading/loading";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import { towns } from "../../../services/towns.js";
import "./formSubsidioAlimentacion.scss";
import Modal from "../../atoms/modal/modal";
import {
   postUser,
   getVerificator,
   getDepartments,
   getMunicipalities,
   getToken,
} from "../../../services/subsidioCpService";
import GlobalContext from "../../../contexts/globalContext";
import iconoAlerta from "../../../assets/icons/modal_alerta.svg";
import moment from "moment";

export interface Root {
   data: Data;
}

export interface Data {
   callId: string;
   apiVersion: number;
   statusReason: string;
   ignoredFields: string;
   id_token: string;
   errorCode: number;
   time: string;
   statusCode: number;
}

const FormSubsidioAlimentacion = (props: any) => {
   const { gigyaAccount } = useLoginGigya();
   const arrForm = props;
   const defaultFormValues = {
      firstName: "",
      lastName: "",
      idNumType: "",
      idNum: "",
      cellPhone: "",
      email: "",
      via: "",
      number1: "",
      crossLetter1: "",
      cardinalPoint1: "",
      number2: "",
      crossLetter2: "",
      cardinalPoint2: "",
      number3: "",
      sapAddress: "",
      department: "",
      municipality: "",
      complementAddress: "",
   };

   const { isBrowser } = useBrowserMode();
   const [departments, setDepartments] = useState<any>([]);
   const [municipalities, setMunicipalities] = useState<any>([]);
   const [formValues, setFormValues] = useState(defaultFormValues);
   const Context = useContext(GlobalContext);
   const [checkBox, setCheckBox] = useState(false);
   const [isEndingForm, setIsEndingForm] = useState(false);
   const [isSubsideEatFirst, setIsSubsideEatFirst] = useState(false);
   const [isShowModalEndingForm, setIsShowModalEndingForm] = useState(false);
   const [isService, setisService] = useState(false);
   const [loading, setloading] = useState(false);
   const [errorCatch, seterrorCatch] = useState(false);
   const [CodeTypeGender, setCodeTypeGender] = useState("");
   const [CodeTypeDoc, setCodeTypeDoc] = useState("");
   const [developerMessage, setDeveloperMessage] = useState("");
   const [manifestAccept, setManifestAccept] = useState(false);
   const [validatorAccessComfama, setValidatorAccessComfama] = useState(false);
   const [saveDataSuccesful, setSaveDataSuccesful] = useState(false);

   const validatorComfama = async (dataRequest: any) => {
      try {
         let token : any;
         if (Context.gigyaContext.gigyaAccount) {
             token = await getToken(Context.gigyaContext.gigyaAccount.UID);
         }
         getVerificator(token.id_token, dataRequest).then(async (result) => {
            if (result.data.status === 200) {
               setloading(false);
               setValidatorAccessComfama(result.data.data);
            } else {
               setValidatorAccessComfama(false);
            }
         });
      } catch (error) {
         setValidatorAccessComfama(false);
         setloading(false);
         console.log(error);
      }
   };

   const getPreFixVia = (key: string): string => {
      switch (key) {
         case "Avenida":
            return "AV";
         case "Circular":
            return "CIR";
         case "Calle":
            return "CL";
         case "Carrera":
            return "CR";
         case "Circunvalar":
            return "CRV";
         case "Diagonal":
            return "DG";
         case "Transversal":
            return "TV";
         default:
            return key;
      }
   };
   const detAddressSAP = (): string => {
      const streetOrVia = formValues.via
         ? `${getPreFixVia(formValues.via)} `
         : "";
      const bodyOneAdress = `${formValues.number1}${formValues.crossLetter1}${formValues.cardinalPoint1} `;
      const bodyTwoAdress = formValues.number2
         ? `# ${formValues.number2}${formValues.crossLetter2}${formValues.cardinalPoint2} - ${formValues.number3}`
         : "";
      const body =
         getPreFixVia(formValues.via).length <= 3
            ? bodyOneAdress +
              bodyTwoAdress +
              " " +
              formValues.complementAddress.toUpperCase()
            : formValues.complementAddress.toUpperCase();
      return streetOrVia + body;
   };

   useEffect(() => {
      //You will able this state and set in each render element's conditional to enable/disable subside 
      setIsShowModalEndingForm(true)
   },[])

   useEffect(() => {
      setIsSubsideEatFirst(true)
      updateFormValues(detAddressSAP(), "sapAddress");
   }, [
      formValues.via,
      formValues.via,
      formValues.number1,
      formValues.number2,
      formValues.number3,
      formValues.complementAddress,
   ]);

   // Actualiza el estado formValues con los inputs.
   const updateFormValues = (value: any, name?: any) => {
      // Covierte los strings del formulario a mayuscula.
      if (name === "firstName" || name === "lastName") {
         name && setFormValues({ ...formValues, [name]: value.toUpperCase() });
      } else if (name === "department") {
         const item = departments.find((element) => element?.name === value);
         getMunicipalities(parseInt(item.id)).then((response) => {
            setMunicipalities(response.data.data);
         });

         name &&
            setFormValues({ ...formValues, [name]: item.id, municipality: "" });
      } else {
         name && setFormValues({ ...formValues, [name]: value });
      }
   };

   const handleSendMessage = async () => {
      try {
         setloading(true);
         const element = municipalities.find(
            (element) => element?.name === formValues.municipality
         );
         const req = await postUser({
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            cellphone: formValues.cellPhone,
            manifestAccept: checkBox,
            postulateDate: moment(Date.now()).format("DD/MM/YYYY HH:mm:ss"),
            idNumType: formValues.idNumType,
            idNum: formValues.idNum,
            addressSap: formValues.sapAddress,
            municipality: {
               id: element.id,
               name: formValues.municipality,
            },
         });
         if (req.data.status === 200) {
            setDeveloperMessage(req.data.developerMessage);
            setisService(false);
            setIsEndingForm(true);
            setloading(false);
         } else {
            setDeveloperMessage(req.data.developerMessage);
            setisService(false);
            seterrorCatch(true);
            setloading(false);
         }
      } catch (error) {
         setloading(false);
         seterrorCatch(true);
         console.log(error);
      }
   };

   // Valida los parametros obligatorios antes de continuar.
   const validateForm = () => {
      return (
         formValues.firstName === "" ||
         formValues.lastName === "" ||
         formValues.idNumType === "" ||
         formValues.idNum === "" ||
         formValues.cellPhone.length < 10 ||
         formValues.cellPhone === "" ||
         formValues.email === "" ||
         formValues.via === "" ||
         (formValues.number1 === "" &&
            getPreFixVia(formValues.via).length <= 3) ||
         (formValues.number2 === "" &&
            getPreFixVia(formValues.via).length <= 3) ||
         (formValues.number3 === "" &&
            getPreFixVia(formValues.via).length <= 3) ||
         formValues.department === "" ||
         formValues.municipality === "" ||
         !checkBox
      );
   };

   const referencePointDetected = (_input): boolean => {
      if (
         _input.nombreId === "number1" &&
         getPreFixVia(formValues.via).length > 3
      ) {
         return true;
      }

      if (
         _input.nombreId === "crossLetter1" &&
         getPreFixVia(formValues.via).length > 3
      ) {
         return true;
      }

      if (
         _input.nombreId === "cardinalPoint1" &&
         getPreFixVia(formValues.via).length > 3
      ) {
         return true;
      }
      if (
         _input.nombreId === "number2" &&
         getPreFixVia(formValues.via).length > 3
      ) {
         return true;
      }
      if (
         _input.nombreId === "crossLetter2" &&
         getPreFixVia(formValues.via).length > 3
      ) {
         return true;
      }

      if (
         _input.nombreId === "cardinalPoint2" &&
         getPreFixVia(formValues.via).length > 3
      ) {
         return true;
      }

      if (
         _input.nombreId === "number3" &&
         getPreFixVia(formValues.via).length > 3
      ) {
         return true;
      }

      return false;
   };

   // Valida que los campos obligatorios esten diligenciados de lo contrario activa el mensaje de error del campo.
   const errorDetected = (_input): boolean => {
      if (
         _input.nombreId === "firstName" &&
         formValues.firstName.length === 0 &&
         manifestAccept
      ) {
         return true;
      }

      if (
         _input.nombreId === "lastName" &&
         formValues.lastName.length === 0 &&
         manifestAccept
      ) {
         return true;
      }

      if (
         _input.nombreId === "idNum" &&
         formValues.idNum.length === 0 &&
         manifestAccept
      ) {
         return true;
      }
      if (
         _input.nombreId === "idNumType" &&
         formValues.idNumType.length === 0 &&
         manifestAccept
      ) {
         return true;
      }

      if (
         _input.nombreId === "cellPhone" &&
         formValues.cellPhone.length != 10 &&
         manifestAccept
      ) {
         return true;
      }

      if (
         _input.nombreId === "email" &&
         formValues.email.length === 0 &&
         manifestAccept
      ) {
         return true;
      }
      if (
         _input.nombreId === "via" &&
         formValues.via.length === 0 &&
         manifestAccept
      ) {
         return true;
      }
      if (
         _input.nombreId === "number1" &&
         formValues.number1.length === 0 &&
         manifestAccept &&
         getPreFixVia(formValues.via).length <= 3
      ) {
         return true;
      }
      if (
         _input.nombreId === "number2" &&
         formValues.number2.length === 0 &&
         manifestAccept &&
         getPreFixVia(formValues.via).length <= 3
      ) {
         return true;
      }
      if (
         _input.nombreId === "number3" &&
         formValues.number3.length === 0 &&
         manifestAccept &&
         getPreFixVia(formValues.via).length <= 3
      ) {
         return true;
      }
      if (
         _input.nombreId === "department" &&
         formValues.department.length === 0 &&
         manifestAccept
      ) {
         return true;
      }
      if (
         _input.nombreId === "municipality" &&
         formValues.municipality.length === 0 &&
         manifestAccept
      ) {
         return true;
      }

      return false;
   };

   const getInput = (_input, _i) => {
      switch (_input.tipoCampo) {
         case "file":
            return (
               <div className="input__file__container" key={`input-${_i}`}>
                  <h6 className="input__file__container__h6">
                     {_input.inputPrincipal}
                  </h6>
                  <InputFiles
                     key={`inp${_i}`}
                     tabIndex={1 + _i}
                     title={_input.inputPrincipal}
                     nameId={_input.nombreId}
                     value={formValues[_input.nombreId]}
                     handleChange={(e) => updateFormValues(e, _input.nombreId)}
                  />
               </div>
            );
         case "predict":
            return (
               <div className="input__container" key={`input-${_i}`}>
                  <h6 className="input__container__h6">
                     {_input.inputPrincipal}
                  </h6>
                  <Input
                     tabIndex={1 + _i}
                     key={`inp${_i}`}
                     title={_input.inputPrincipal}
                     type={_input.tipoCampo}
                     nameId={_input.nombreId}
                     value={formValues[_input.nombreId]}
                     handleChange={(e) => updateFormValues(e, _input.nombreId)}
                     isError={errorDetected(_input)}
                     isReferencePoint={referencePointDetected(_input)}
                     errorMessage={_input.mensajeError}
                     options={towns}
                     placeholder={
                        _input.tipoCampo === "select" &&
                        _input.nombreId === "state"
                           ? _input.listaSelect[0]
                           : ""
                     }
                     setCodeTypeGender={CodeTypeGender}
                     setCodeTypeDoc={CodeTypeDoc}
                     gigyaAccount={gigyaAccount}
                     isSubsideEatFirst={isSubsideEatFirst}
                  />
               </div>
            );
         case "select-key-value":
            return (
               <div className="input__container" key={`input-${_i}`}>
                  <h6 className="input__container__h6">
                     {_input.inputPrincipal}
                  </h6>
                  <Input
                     tabIndex={1 + _i}
                     key={`inp${_i}`}
                     title={_input.inputPrincipal}
                     type={_input.tipoCampo}
                     nameId={_input.nombreId}
                     value={formValues[_input.nombreId]}
                     handleChange={(e) => updateFormValues(e, _input.nombreId)}
                     isError={errorDetected(_input)}
                     isReferencePoint={referencePointDetected(_input)}
                     errorMessage={_input.mensajeError}
                     options={
                        (_input.nombreId === "municipality" &&
                           municipalities) ||
                        (_input.nombreId === "department" && departments)
                     }
                     setCodeTypeGender={CodeTypeGender}
                     setCodeTypeDoc={CodeTypeDoc}
                     gigyaAccount={gigyaAccount}
                     isSubsideEatFirst={isSubsideEatFirst}
                  />
               </div>
            );
         default:
            return (
               <div className="input__container" key={`input-${_i}`}>
                  <h6 className="input__container__h6">
                     {_input.inputPrincipal}
                  </h6>
                  <Input
                     tabIndex={1 + _i}
                     key={`inp${_i}`}
                     title={_input.inputPrincipal}
                     type={_input.tipoCampo}
                     nameId={_input.nombreId}
                     value={formValues[_input.nombreId]}
                     handleChange={(e) => updateFormValues(e, _input.nombreId)}
                     isError={errorDetected(_input)}
                     isReferencePoint={referencePointDetected(_input)}
                     errorMessage={_input.mensajeError}
                     options={_input.listaSelect || null}
                     placeholder={
                        _input.tipoCampo === "select" &&
                        _input.nombreId === "state"
                           ? _input.listaSelect[0]
                           : ""
                     }
                     setCodeTypeDoc={setCodeTypeDoc}
                     setCodeTypeGender={setCodeTypeGender}
                     gigyaAccount={gigyaAccount}
                     isSubsideEatFirst={isSubsideEatFirst}
                  />
               </div>
            );
      }
   };
      const enablePopUpGyiga = () => {
      const timer = setTimeout(() => {
         if (!Context.gigyaContext.gigyaAccount) {
            Context.gigyaContext.login;
         }
      }, 4000);
      return () => clearTimeout(timer);
   };

   useEffect(() => {
      if(!isShowModalEndingForm){
      if (!Context.gigyaContext.gigyaAccount) {
         enablePopUpGyiga();
      } else {
         if (isBrowser && Context.gigyaContext.gigyaAccount) {
            props.setIsUser(true);
            getDepartments().then((response) =>
               setDepartments(response.data.data)
            );
            var typeId: string = "";
            if (Context.gigyaContext.gigyaAccount.data.docType === "CO1C") {
               typeId = "Cédula ciudadanía";
               setCodeTypeDoc("01");
            }
            if (Context.gigyaContext.gigyaAccount.data.docType === "CO1T") {
               typeId = "Tarjeta de identidad";
               setCodeTypeDoc("02");
            }
            if (Context.gigyaContext.gigyaAccount.data.docType === "CO1L") {
               typeId = "Registro civil";
               setCodeTypeDoc("03");
            }
            if (Context.gigyaContext.gigyaAccount.data.docType === "CO1E") {
               typeId = "Cédula de extranjería";
               setCodeTypeDoc("05");
            }
            if (Context.gigyaContext.gigyaAccount.data.docType === "CO1P") {
               typeId = "Pasaporte";
               setCodeTypeDoc("08");
            }
            if (Context.gigyaContext.gigyaAccount.data.docType === "CO1V") {
               typeId = "Permiso especial de permanencia";
               setCodeTypeDoc("09");
            }
            if (Context.gigyaContext.gigyaAccount.data.docType === "CO1W") {
               typeId = "Permiso por Protección Temporal";
               setCodeTypeDoc("11");
            }
            if (document.getElementById("idNumType")) {
               document.getElementById("idNumType").innerHTML = typeId;
            }

            setFormValues({
               ...formValues,
               firstName: `${
                  Context.gigyaContext.gigyaAccount.data.secondName
                     ? Context.gigyaContext.gigyaAccount.profile.firstName +
                       " " +
                       Context.gigyaContext.gigyaAccount.data.secondName
                     : Context.gigyaContext.gigyaAccount.profile.firstName
               }`,
               lastName: `${
                  Context.gigyaContext.gigyaAccount.data.secondLastName
                     ? Context.gigyaContext.gigyaAccount.profile.lastName +
                       " " +
                       Context.gigyaContext.gigyaAccount.data.secondLastName
                     : Context.gigyaContext.gigyaAccount.profile.lastName
               }`,
               cellPhone: Context.gigyaContext.gigyaAccount.data?.mobile ?? "",
               email: Context.gigyaContext.gigyaAccount.profile.email,
               idNumType: typeId,
               idNum: Context.gigyaContext.gigyaAccount.data.docNumber,
            });
         } else if (formValues.idNumType) {
            window.location.href = "/subsidio-alimenticiocp";
         }
      }
   }
   }, [Context.gigyaContext.gigyaAccount]);

   useEffect(() => {
      setloading(true);
      if (Context.gigyaContext.gigyaAccount && CodeTypeDoc && !isShowModalEndingForm){
         validatorComfama({
            idNum: formValues.idNum,
            idNumType: CodeTypeDoc,
         });}
   }, [Context.gigyaContext.gigyaAccount, CodeTypeDoc]);

   useEffect(() => {
      let idNumber = document.getElementById("idNum");

      idNumber?.addEventListener(
         "change",
         (e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value;
            let initialValue;

            if (value[0] == "0") {
               e.target.value = initialValue;
            }
         }
      );
   }, [formValues.idNum]);

   useEffect(() => {
      let idNumber = document.getElementById("cellPhone");
      idNumber?.addEventListener(
         "change",
         (e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value;
            let initialValue;
            if (value[0] == "0") {
               e.target.value = initialValue;
            }
         }
      );
   }, [formValues.cellPhone]);

   useEffect(() => {
      if (
         Context.gigyaContext.gigyaAccount?.UID &&
         !localStorage.getItem("uid-pre")
      ) {
         localStorage.setItem(
            "uid-pre",
            Context.gigyaContext.gigyaAccount?.UID
         );
         window.location.reload();
      }
   }, [Context.gigyaContext.gigyaAccount]);
   // set to able form !formValues.firstName || !Context.gigyaContext.gigyaAccount and the conditon : null at the modal and isEndingForm to Open Modal 
   return !isShowModalEndingForm ? (
      <>
         {" "}
         <div className="modal-validate-access__content">
            <div className="modal-validate-access__content__body">
               <img
                  src={iconoAlerta}
                  className="modal-validate-access__content__body__image"
               />
               <h1 className="modal-validate-access__content__body__title">
                  Ten en cuenta lo siguiente ...
               </h1>
               <p>
                  Para realizar la respectiva postulación es necesario que
                  inicies sesión
               </p>

               <br />
               <Button
                  type="button"
                  classname="mentoria-not-logged-container__button"
                  gtmMark="secondary"
                  text="Iniciar Sesión"
                  onClickAction={() => Context.gigyaContext.login()}
                  isDisabled={false}
               />
            </div>
         </div>
      </>
   ) : validatorAccessComfama || isShowModalEndingForm ? (
      <>
         {!isService && isEndingForm || isShowModalEndingForm ? (
            <Modal open={true} onClose={() => false}>
               <div className="o-form-success-pre">
                  <div className="o-form-success-pre__title">
                     <h3>Postulación Subsidio de Alimentación</h3>
                  </div>
                  <div className="o-form-success-pre__content">
                     <p className="o-form-success-pre__content__info">
                     <p>Hemos cerrado las postulaciones al <strong>Programa y Subsidio Comer es Primero.</strong> Estaremos enviando la respuesta a las postulaciones a través de correo electrónico el 24 de agosto. Te invitamos a estar conectado con nuestras redes y canales para más información y novedades del programa.</p>
                     </p>
                  </div>

                  <a
                    
                     className="o-form-success-step2__content__btnend"
                     onClick={() => {window.location.protocol +"/subsidio/bono-alimentacion"}}
                     href={typeof window !== "undefined" ? window.location.protocol +  "/subsidio/bono-alimentacion" : ""}                   
                  >
                     ACEPTAR
                  </a>
               </div>
            </Modal>
         ) : 
         <div className="o-form">
            <p className="o-contact__header-message"></p>
            <div id="group0" className="o-form__group--open">
               <span className="o-form__person-title">
                  {" "}
                  {arrForm[0].tituloGeneral || ""}{" "}
               </span>
               <div className="o-form__group-cont">
                  {arrForm &&
                     arrForm[0].referenciaFormulario.map((inp, i) =>
                        getInput(inp, i)
                     )}
               </div>
               <div className="o-form__bottom">
                  <label className="o-form__person-label">
                     Dirección de Residencia
                  </label>
                  <br />
                  <p> {detAddressSAP()}</p>
               </div>
            </div>

            <div className="o-form__bottom">
               <div className="o-form__bottom-txt">
                  <p>
                     <strong>Postulación Subsidio Alimentación #ComerEsPrimero</strong>

                     <p>
                        ¡ Hola{" "}
                        {" " +
                           formValues.firstName +
                           " " +
                           formValues.lastName +
                           " "}{" "}
                        !
                     </p>
                     <p>
                        Estás un paso más cerca de nutrirte y nutrir a tu
                        familia con el bono de alimentación. Para finalizar tu
                        proceso de postulación para el subsidio
                        #ComerEsLoPrimero, te invitamos a asumir este
                        compromiso:
                     </p>
                     <p>
                        En caso de ser seleccionado/a como beneficiario/a con la
                        diligencia de este formulario, yo, me comprometo a
                        redimir y hacer uso del bono de alimentación otorgado
                        por Comfama mensualmente.
                     </p>
                     <p>
                        Con este subsidio priorizaré en la compra del mercado
                        familiar los alimentos que más nos nutren para cuidarnos
                        y progresar juntos
                     </p>
                     <p>( X ) Acepto</p>
                  </p>
               </div>
               <div className="o-form__bottom-btns">
                  <div className="o-form__bottom-check">
                     <input
                        type="checkbox"
                        id="cbox1"
                        value="first_checkbox"
                        onChange={(e) => {
                           setCheckBox(e.target.checked);
                           setManifestAccept(true);
                        }}
                     />
                     <label
                        htmlFor="cbox1"
                        onClick={() => setManifestAccept(true)}
                     >
                        {arrForm[1].referenciaFormulario[0].tituloBotonTerminos}
                     </label>
                  </div>
                  <div className="o-form__bottom-btn">
                     {loading ? (
                        <Loading />
                     ) : (
                        <Button
                           id={"btn-enviar"}
                           type={"button"}
                           classname={`secondary `}
                           text="Postularme"
                           isDisabled={validateForm()}
                           gtmMark={"gtmFormularioPreinscripcionContinuar"}
                           onClickAction={() => {
                              setSaveDataSuccesful(true);
                              setisService(!isService);
                              handleSendMessage();
                           }}
                        />
                     )}
                  </div>
               </div>
            </div>
            {/* -------------------------------------------- */}
         </div>
      }
         {errorCatch ? (
            <Modal open={errorCatch}>
               <div className="o-form-success">
                  <div className="o-form-success__content">
                     <i className="icon__check icon-close" />
                     <h3 className="o-form-success__content__message">
                        Su postulación no pudo ser enviada
                     </h3>
                     <p className="o-form-success__content__info">
                        {developerMessage
                           ? developerMessage
                           : "En este momento no podemos procesar su solicitud, porfavor intentelo mas tarde."}
                     </p>
                     <a
                        href=""
                        onClick={() => seterrorCatch(!errorCatch)}
                        className="o-form-success__content__btn"
                     >
                        ACEPTAR
                     </a>
                  </div>
               </div>
            </Modal>
         ) : null}
      </>
   ) : (
      <>
         <div className="modal-validate-access__content">
            <div className="modal-validate-access__content__body">
               <img
                  src={iconoAlerta}
                  className="modal-validate-access__content__body__image"
               />
               <h1 className="modal-validate-access__content__body__title">
                  Información Importante
               </h1>
               <p>
                  Celebramos que busques nutrirte y nutrir a tu familia con el
                  bono de alimentación. Sin embargo, hemos recibido tu
                  postulación y no cumples con los requisitos para
                  recibir el subsidio #ComerEsLoPrimero. Te invitamos a soñar
                  junto a tu familia una ruta de cuidado que prepararemos para
                  ti a través de nuestros servicios:
               </p>
               <p>
                  <a
                     href="https://www.comfama.com/finanzas/educacion-financiera"
                     target="_blank"
                     rel="noreferrer"
                  >
                     Mentoria Financiera
                  </a>
                  <br />
                  <a
                     href="https://www.comfama.com/aprendizaje/oferta-becas"
                     target="_blank"
                     rel="noreferrer"
                  >
                     Programas de becas
                  </a>
                  <br />
                  <a
                     href="https://www.comfama.com/trabajo-con-proposito/empleo/mentoria-de-talento"
                     target="_blank"
                     rel="noreferrer"
                  >
                     Mentorías de talento
                  </a>
                  <br />
                  <a
                     href="https://www.comfama.com/trabajo-con-proposito/empleo/alianza-empresarial/mapa-de-talento/"
                     target="_blank"
                     rel="noreferrer"
                  >
                     Programas como Alianza
                  </a>
                  <br />
               </p>
               <br />
            </div>
         </div>
      </>
   );
};

export default FormSubsidioAlimentacion;
