import axios from "axios";
const formUrl = `${process.env.FORM_SUBSIDIO_ALIMENTACION}/api/v1/save`;
const verificator = `${process.env.FORM_SUBSIDIO_ALIMENTACION}/api/v1/verifier`;
const dptoUrl = `${process.env.SUBSIDIO_DPTO}`;
const mcpioUrl = `${process.env.SUBSIDIO_MCPIO}`;
const tokenURL = `${process.env.URL_COMFAMA_TOKEN}`;
const tokenApiKey = `${process.env.URL_COMFAMA_TOKEN_KEY}`;

interface dataVerificator {
   idNum: string;
   idNumType: string;
}

interface dataForm {
   firstName: string;
   lastName: string;
   email: string;
   cellphone: string;
   manifestAccept: boolean;
   postulateDate: string;
   idNumType: string;
   idNum: string;
   addressSap: string;
   municipality: { id: number; name: string };
}

const postUser = async (tempDataForm: dataForm) => {
   const data = await axios.post(`${formUrl}`, {
      firstName: tempDataForm.firstName,
      lastName: tempDataForm.lastName,
      email: tempDataForm.email,
      cellphone: tempDataForm.cellphone,
      manifestAccept: tempDataForm.manifestAccept,
      postulateDate: tempDataForm.postulateDate,
      idNumType: tempDataForm.idNumType,
      idNum: tempDataForm.idNum,
      addressSap: tempDataForm.addressSap,
      municipality: {
         id: tempDataForm.municipality.id,
         name: tempDataForm.municipality.name,
      },
   });
   return data;
};

const getToken = async (uid: string) => {
   if (tokenURL) {
      const response = await axios.post(
         tokenURL,
         {
            action: "getJWT",
            UID: uid,
         },
         {
            headers: {
               "Content-Type": "application/json;charset=UTF-8",
               ApiKey: tokenApiKey,
            },
         }
      );

      return response.data.data;
   }
};


const getVerificator = async (id_token:string,dataRequest: dataVerificator) => {
   const data = await axios.get(
      `${verificator}/${dataRequest.idNum}/${dataRequest.idNumType}`, { headers: {"Authorization" : `Bearer ${id_token}`} }
   );
   return data;
};

const getMunicipalities = async (departmentId: number) => {
   const data = await axios.get(`${mcpioUrl}/${departmentId}`);
   return data;
};

const getDepartments = async () => {
   const data = await axios.get(`${dptoUrl}`);
   return data;
};

export {
   postUser,
   getToken,
   getMunicipalities,
   getDepartments,
   getVerificator,
};
