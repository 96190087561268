export const towns = [
  "MEDELLÍN, ANTIOQUIA",
  "ABEJORRAL, ANTIOQUIA",
  "ABRIAQUÍ, ANTIOQUIA",
  "ALEJANDRÍA, ANTIOQUIA",
  "AMAGa, ANTIOQUIA",
  "AMALFI, ANTIOQUIA",
  "ANDES, ANTIOQUIA",
  "ANGELOPOLIS, ANTIOQUIA",
  "ANGOSTURA, ANTIOQUIA",
  "ANORÍ, ANTIOQUIA",
  "SANTAFÉ DE ANTIOQUIA, ANTIOQUIA",
  "ANZA, ANTIOQUIA",
  "APARTADÓ, ANTIOQUIA",
  "ARBOLETES, ANTIOQUIA",
  "ARGELIA, ANTIOQUIA",
  "ARMENIA, ANTIOQUIA",
  "BARBOSA, ANTIOQUIA",
  "BELMIRA, ANTIOQUIA",
  "BELLO, ANTIOQUIA",
  "BETANIA, ANTIOQUIA",
  "BETULIA, ANTIOQUIA",
  "CIUDAD BOLÍVAR, ANTIOQUIA",
  "BRICEÑO, ANTIOQUIA",
  "BURITICÁ, ANTIOQUIA",
  "CÁCERES, ANTIOQUIA",
  "CAICEDO, ANTIOQUIA",
  "CALDAS, ANTIOQUIA",
  "CAMPAMENTO, ANTIOQUIA",
  "CAÑASGORDAS, ANTIOQUIA",
  "CARACOLÍ, ANTIOQUIA",
  "CARAMANTA, ANTIOQUIA",
  "CAREPA, ANTIOQUIA",
  "CARMEN DE VIBORAL, ANTIOQUIA",
  "CAROLINA, ANTIOQUIA",
  "CAUCASIA, ANTIOQUIA",
  "CHIGORODÓ, ANTIOQUIA",
  "CISNEROS, ANTIOQUIA",
  "COCORNÁ, ANTIOQUIA",
  "CONCEPCIÓN, ANTIOQUIA",
  "CONCORDIA, ANTIOQUIA",
  "COPACABANA, ANTIOQUIA",
  "DABEIBA, ANTIOQUIA",
  "DON MATiAS, ANTIOQUIA",
  "EBÉJICO, ANTIOQUIA",
  "EL BAGRE, ANTIOQUIA",
  "ENTRERRIOS, ANTIOQUIA",
  "ENVIGADO, ANTIOQUIA",
  "FREDONIA, ANTIOQUIA",
  "FRONTINO, ANTIOQUIA",
  "GIRALDO, ANTIOQUIA",
  "GIRARDOTA, ANTIOQUIA",
  "GÓMEZ PLATA, ANTIOQUIA",
  "GRANADA, ANTIOQUIA",
  "GUADALUPE, ANTIOQUIA",
  "GUARNE, ANTIOQUIA",
  "GUATAPE, ANTIOQUIA",
  "HELICONIA, ANTIOQUIA",
  "HISPANIA, ANTIOQUIA",
  "ITAGUI, ANTIOQUIA",
  "ITUANGO, ANTIOQUIA",
  "JARDÍN, ANTIOQUIA",
  "JERICÓ, ANTIOQUIA",
  "LA CEJA, ANTIOQUIA",
  "LA ESTRELLA, ANTIOQUIA",
  "LA PINTADA, ANTIOQUIA",
  "LA UNIÓN, ANTIOQUIA",
  "LIBORINA, ANTIOQUIA",
  "MACEO, ANTIOQUIA",
  "MARINILLA, ANTIOQUIA",
  "MONTEBELLO, ANTIOQUIA",
  "MURINDÓ, ANTIOQUIA",
  "MUTATA, ANTIOQUIA",
  "NARIÑO, ANTIOQUIA",
  "NECOCLÍ, ANTIOQUIA",
  "NECHÍ, ANTIOQUIA",
  "OLAYA, ANTIOQUIA",
  "PEÑOL, ANTIOQUIA",
  "PEQUE, ANTIOQUIA",
  "PUEBLORRICO, ANTIOQUIA",
  "PUERTO BERRiO, ANTIOQUIA",
  "PUERTO NARE, ANTIOQUIA",
  "PUERTO TRIUNFO, ANTIOQUIA",
  "REMEDIOS, ANTIOQUIA",
  "RETIRO, ANTIOQUIA",
  "RIONEGRO, ANTIOQUIA",
  "SABANALARGA, ANTIOQUIA",
  "SABANETA, ANTIOQUIA",
  "SALGAR, ANTIOQUIA",
  "SAN ANDRÉS, ANTIOQUIA",
  "SAN CARLOS, ANTIOQUIA",
  "SAN FRANCISCO, ANTIOQUIA",
  "SAN JERÓNIMO, ANTIOQUIA",
  "SAN JOSÉ DE LA MONTAÑA, ANTIOQUIA",
  "SAN JUAN DE URABA, ANTIOQUIA",
  "SAN LUIS, ANTIOQUIA",
  "SAN PEDRO, ANTIOQUIA",
  "SAN PEDRO DE URABA, ANTIOQUIA",
  "SAN RAFAEL, ANTIOQUIA",
  "SAN ROQUE, ANTIOQUIA",
  "SAN VICENTE, ANTIOQUIA",
  "SANTA BaRBARA, ANTIOQUIA",
  "SANTA ROSA de osos, ANTIOQUIA",
  "SANTO DOMINGO, ANTIOQUIA",
  "SANTUARIO, ANTIOQUIA",
  "SEGOVIA, ANTIOQUIA",
  "SONSON, ANTIOQUIA",
  "SOPETRaN, ANTIOQUIA",
  "TÁMESIS, ANTIOQUIA",
  "TARAZÁ, ANTIOQUIA",
  "TARSO, ANTIOQUIA",
  "TITIRIBÍ, ANTIOQUIA",
  "TOLEDO, ANTIOQUIA",
  "TURBO, ANTIOQUIA",
  "URAMITA, ANTIOQUIA",
  "URRAO, ANTIOQUIA",
  "VALDIVIA, ANTIOQUIA",
  "VALPARAISO, ANTIOQUIA",
  "VEGACHÍ, ANTIOQUIA",
  "VENECIA, ANTIOQUIA",
  "VIGÍA DEL FUERTE, ANTIOQUIA",
  "YALÍ, ANTIOQUIA",
  "YARUMAL, ANTIOQUIA",
  "YOLOMBÓ, ANTIOQUIA",
  "YONDÓ, ANTIOQUIA",
  "ZARAGOZA, ANTIOQUIA",
  "BARRANQUILLA, ATLÁNTICO",
  "BARANOA, ATLÁNTICO",
  "CAMPO DE LA CRUZ, ATLÁNTICO",
  "CANDELARIA, ATLÁNTICO",
  "GALAPA, ATLÁNTICO",
  "JUAN DE ACOSTA, ATLÁNTICO",
  "LURUACO, ATLÁNTICO",
  "MALAMBO, ATLÁNTICO",
  "MANATi, ATLÁNTICO",
  "PALMAR DE VARELA, ATLÁNTICO",
  "PIOJÓ, ATLÁNTICO",
  "POLONUEVO, ATLÁNTICO",
  "PONEDERA, ATLÁNTICO",
  "PUERTO COLOMBIA, ATLÁNTICO",
  "REPELON, ATLÁNTICO",
  "Sabanagrande, ATLÁNTICO",
  "SABANALARGA, ATLÁNTICO",
  "SANTA LUCiA, ATLÁNTICO",
  "Santo Tomas, ATLÁNTICO",
  "SOLEDAD, ATLÁNTICO",
  "SUAN, ATLÁNTICO",
  "TUBARA, ATLÁNTICO",
  "USIACURi, ATLÁNTICO",
  "BOGOTA D.C., BOGOTÁ D.C.",
  "CARTAGENA, BOLIVAR",
  "ACHÍ, BOLIVAR",
  "ALTOS DEL ROSARIO, BOLIVAR",
  "ARENAL, BOLIVAR",
  "ARJONA, BOLIVAR",
  "ARROYOHONDO, BOLIVAR",
  "BARRANCO DE LOBA, BOLIVAR",
  "CALAMAR, BOLIVAR",
  "CANTAGALLO, BOLIVAR",
  "CICUCO, BOLIVAR",
  "CÓRDOBA, BOLIVAR",
  "CLEMENCIA, BOLIVAR",
  "CARMEN DE BOLÍVAR, BOLIVAR",
  "EL GUAMO, BOLIVAR",
  "EL PEÑON, BOLIVAR",
  "HATILLO DE LOBA, BOLIVAR",
  "MAGANGUÉ, BOLIVAR",
  "MAHATES, BOLIVAR",
  "MARGARITA, BOLIVAR",
  "MARÍA LA BAJA, BOLIVAR",
  "MONTECRISTO, BOLIVAR",
  "MOMPÓS, BOLIVAR",
  "MORALES, BOLIVAR",
  "PINILLOS, BOLIVAR",
  "REGIDOR, BOLIVAR",
  "RÍO VIEJO, BOLIVAR",
  "SAN CRISTOBAL, BOLIVAR",
  "SAN ESTANISLAO, BOLIVAR",
  "SAN FERNANDO, BOLIVAR",
  "SAN JACINTO, BOLIVAR",
  "SAN JACINTO DEL CAUCA, BOLIVAR",
  "SAN JUAN NEPOMUCENO, BOLIVAR",
  "SAN MARTIN DE LOBA, BOLIVAR",
  "SAN PABLO, BOLIVAR",
  "SANTA CATALINA, BOLIVAR",
  "SANTA ROSA DE LIMA, BOLIVAR",
  "SANTA ROSA DEL SUR, BOLIVAR",
  "SIMITÍ, BOLIVAR",
  "SOPLAVIENTO, BOLIVAR",
  "TALAIGUA NUEVO, BOLIVAR",
  "TIQUISIO, BOLIVAR",
  "TURBACO, BOLIVAR",
  "TURBANA, BOLIVAR",
  "VILLANUEVA, BOLIVAR",
  "ZAMBRANO, BOLIVAR",
  "TUNJA, BOYACÁ",
  "ALMEIDA, BOYACÁ",
  "AQUITANIA, BOYACÁ",
  "ARCABUCO, BOYACÁ",
  "BELÉN, BOYACÁ",
  "BERBEO, BOYACÁ",
  "BETÉITIVA, BOYACÁ",
  "BOAVITA, BOYACÁ",
  "BOYACÁ, BOYACÁ",
  "BRICEÑO, BOYACÁ",
  "BUENAVISTA, BOYACÁ",
  "BUSBANZÁ, BOYACÁ",
  "CALDAS, BOYACÁ",
  "CAMPOHERMOSO, BOYACÁ",
  "CERINZA, BOYACÁ",
  "CHINAVITA, BOYACÁ",
  "CHIQUINQUIRÁ, BOYACÁ",
  "CHISCAS, BOYACÁ",
  "CHITA, BOYACÁ",
  "CHITARAQUE, BOYACÁ",
  "CHIVATÁ, BOYACÁ",
  "CIÉNEGA, BOYACÁ",
  "CÓMBITA, BOYACÁ",
  "COPER, BOYACÁ",
  "CORRALES, BOYACÁ",
  "COVARACHÍA, BOYACÁ",
  "CUBARÁ, BOYACÁ",
  "CUCAITA, BOYACÁ",
  "CUÍTIVA, BOYACÁ",
  "CHÍQUIZA, BOYACÁ",
  "CHIVOR, BOYACÁ",
  "DUITAMA, BOYACÁ",
  "EL COCUY, BOYACÁ",
  "EL ESPINO, BOYACÁ",
  "FIRAVITOBA, BOYACÁ",
  "FLORESTA, BOYACÁ",
  "GACHANTIVÁ, BOYACÁ",
  "GAMEZA, BOYACÁ",
  "GARAGOA, BOYACÁ",
  "GUACAMAYAS, BOYACÁ",
  "GUATEQUE, BOYACÁ",
  "GUAYATÁ, BOYACÁ",
  "GÜICÁN, BOYACÁ",
  "IZA, BOYACÁ",
  "JENESANO, BOYACÁ",
  "JERICÓ, BOYACÁ",
  "LABRANZAGRANDE, BOYACÁ",
  "LA CAPILLA, BOYACÁ",
  "LA VICTORIA, BOYACÁ",
  "LA UVITA, BOYACÁ",
  "VILLA DE LEYVA, BOYACÁ",
  "MACANAL, BOYACÁ",
  "MARIPÍ, BOYACÁ",
  "MIRAFLORES, BOYACÁ",
  "MONGUA, BOYACÁ",
  "MONGUÍ, BOYACÁ",
  "MONIQUIRÁ, BOYACÁ",
  "MOTAVITA, BOYACÁ",
  "MUZO, BOYACÁ",
  "NOBSA, BOYACÁ",
  "NUEVO COLÓN, BOYACÁ",
  "OICATÁ, BOYACÁ",
  "OTANCHE, BOYACÁ",
  "PACHAVITA, BOYACÁ",
  "PÁEZ, BOYACÁ",
  "PAIPA, BOYACÁ",
  "PAJARITO, BOYACÁ",
  "PANQUEBA, BOYACÁ",
  "PAUNA, BOYACÁ",
  "PAYA, BOYACÁ",
  "PAZ DE RÍO, BOYACÁ",
  "PESCA, BOYACÁ",
  "PISBA, BOYACÁ",
  "PUERTO BOYACa, BOYACÁ",
  "QUÍPAMA, BOYACÁ",
  "RAMIRIQUÍ, BOYACÁ",
  "RÁQUIRA, BOYACÁ",
  "RONDÓN, BOYACÁ",
  "SABOYÁ, BOYACÁ",
  "SÁCHICA, BOYACÁ",
  "SAMACÁ, BOYACÁ",
  "SAN EDUARDO, BOYACÁ",
  "SAN JOSÉ DE PARE, BOYACÁ",
  "SAN LUIS DE GACENO, BOYACÁ",
  "SAN MATEO, BOYACÁ",
  "SAN MIGUEL DE SEMA, BOYACÁ",
  "SAN PABLO BORBUR, BOYACÁ",
  "SANTANA, BOYACÁ",
  "SANTA MARÍA, BOYACÁ",
  "SAN ROSA VITERBO, BOYACÁ",
  "SANTA SOFÍA, BOYACÁ",
  "SATIVANORTE, BOYACÁ",
  "SATIVASUR, BOYACÁ",
  "SIACHOQUE, BOYACÁ",
  "SOATÁ, BOYACÁ",
  "SOCOTÁ, BOYACÁ",
  "SOCHA, BOYACÁ",
  "SOGAMOSO, BOYACÁ",
  "SOMONDOCO, BOYACÁ",
  "SORA, BOYACÁ",
  "SOTAQUIRÁ, BOYACÁ",
  "SORACÁ, BOYACÁ",
  "SUSACÓN, BOYACÁ",
  "SUTAMARCHÁN, BOYACÁ",
  "SUTATENZA, BOYACÁ",
  "TASCO, BOYACÁ",
  "TENZA, BOYACÁ",
  "TIBANÁ, BOYACÁ",
  "TIBASOSA, BOYACÁ",
  "TINJACÁ, BOYACÁ",
  "TIPACOQUE, BOYACÁ",
  "TOCA, BOYACÁ",
  "TOGÜÍ, BOYACÁ",
  "TÓPAGA, BOYACÁ",
  "TOTA, BOYACÁ",
  "TUNUNGUÁ, BOYACÁ",
  "TURMEQUÉ, BOYACÁ",
  "TUTA, BOYACÁ",
  "TUTAZÁ, BOYACÁ",
  "UMBITA, BOYACÁ",
  "VENTAQUEMADA, BOYACÁ",
  "VIRACACHÁ, BOYACÁ",
  "ZETAQUIRA, BOYACÁ",
  "MANIZALES, CALDAS",
  "AGUADAS, CALDAS",
  "ANSERMA, CALDAS",
  "ARANZAZU, CALDAS",
  "BELALCÁZAR, CALDAS",
  "CHINCHINa, CALDAS",
  "FILADELFIA, CALDAS",
  "LA DORADA, CALDAS",
  "LA MERCED, CALDAS",
  "MANZANARES, CALDAS",
  "MARMATO, CALDAS",
  "MARQUETALIA, CALDAS",
  "MARULANDA, CALDAS",
  "NEIRA, CALDAS",
  "NORCASIA, CALDAS",
  "PÁCORA, CALDAS",
  "PALESTINA, CALDAS",
  "PENSILVANIA, CALDAS",
  "RIOSUCIO, CALDAS",
  "RISARALDA, CALDAS",
  "SALAMINA, CALDAS",
  "SAMANÁ, CALDAS",
  "SAN JOSÉ, CALDAS",
  "SUPÍA, CALDAS",
  "VICTORIA, CALDAS",
  "VILLAMARiA, CALDAS",
  "VITERBO, CALDAS",
  "FLORENCIA, CAQUETA",
  "ALBANIA, CAQUETA",
  "BELÉN DE LOS ANDAQUIES, CAQUETA",
  "CARTAGENA DEL CHAIRÁ, CAQUETA",
  "CURRILLO, CAQUETA",
  "EL DONCELLO, CAQUETA",
  "EL PAUJIL, CAQUETA",
  "LA MONTAÑITA, CAQUETA",
  "MILaN, CAQUETA",
  "MORELIA, CAQUETA",
  "PUERTO RICO, CAQUETA",
  "SAN JOSE DEL FRAGUA, CAQUETA",
  "SAN VICENTE DEL CAGUÁN, CAQUETA",
  "SOLANO, CAQUETA",
  "SOLITA, CAQUETA",
  "VALPARAISO, CAQUETA",
  "POPAYÁN, CAUCA",
  "ALMAGUER, CAUCA",
  "ARGELIA, CAUCA",
  "BALBOA, CAUCA",
  "BOLÍVAR, CAUCA",
  "BUENOS AIRES, CAUCA",
  "CAJIBÍO, CAUCA",
  "CALDONO, CAUCA",
  "CALOTO, CAUCA",
  "CORINTO, CAUCA",
  "EL TAMBO, CAUCA",
  "FLORENCIA, CAUCA",
  "GUAPI, CAUCA",
  "INZÁ, CAUCA",
  "JAMBALO, CAUCA",
  "LA SIERRA, CAUCA",
  "LA VEGA, CAUCA",
  "LOPEZ, CAUCA",
  "MERCADERES, CAUCA",
  "MIRANDA, CAUCA",
  "MORALES, CAUCA",
  "PADILLA, CAUCA",
  "PAEZ, CAUCA",
  "PATIA, CAUCA",
  "PIAMONTE, CAUCA",
  "PIENDAMO, CAUCA",
  "PUERTO TEJADA, CAUCA",
  "PURACE, CAUCA",
  "ROSAS, CAUCA",
  "SAN SEBASTIAN, CAUCA",
  "SANTANDER DE QUILICHAO, CAUCA",
  "SANTA ROSA, CAUCA",
  "Silvia, CAUCA",
  "SOTARA, CAUCA",
  "SUAREZ, CAUCA",
  "SUCRE, CAUCA",
  "TIMBIO, CAUCA",
  "TIMBIQUI, CAUCA",
  "TORIBIO, CAUCA",
  "TOTORO, CAUCA",
  "VILLA RICA, CAUCA",
  "VALLEDUPAR, CESAR",
  "AGUACHICA, CESAR",
  "AGUSTÍN CODAZZI, CESAR",
  "ASTREA, CESAR",
  "BECERRIL, CESAR",
  "BOSCONIA, CESAR",
  "CHIMICHAGUA, CESAR",
  "CHIRIGUANA, CESAR",
  "CURUMANÍ, CESAR",
  "EL COPEY, CESAR",
  "EL PASO, CESAR",
  "GAMARRA, CESAR",
  "GONZÁLEZ, CESAR",
  "LA GLORIA, CESAR",
  "LA JAGUA DE IBIRICO, CESAR",
  "MANAURE, CESAR",
  "PAILITAS, CESAR",
  "PELAYA, CESAR",
  "PUEBLO BELLO, CESAR",
  "RÍO DE ORO, CESAR",
  "LA PAZ, CESAR",
  "SAN ALBERTO, CESAR",
  "SAN DIEGO, CESAR",
  "SAN MARTÍN, CESAR",
  "TAMALAMEQUE, CESAR",
  "MONTERÍA, CORDOBA",
  "AYAPEL, CORDOBA",
  "BUENAVISTA, CORDOBA",
  "CANALETE, CORDOBA",
  "CERETÉ, CORDOBA",
  "CHIMÁ, CORDOBA",
  "CHINÚ, CORDOBA",
  "CIÉNAGA DE ORO, CORDOBA",
  "COTORRA, CORDOBA",
  "LA APARTADA, CORDOBA",
  "LORICA, CORDOBA",
  "LOS CÓRDOBAS, CORDOBA",
  "MOMIL, CORDOBA",
  "MONTELÍBANO, CORDOBA",
  "MOÑITOS, CORDOBA",
  "PLANETA RICA, CORDOBA",
  "PUEBLO NUEVO, CORDOBA",
  "PUERTO ESCONDIDO, CORDOBA",
  "PUERTO LIBERTADOR, CORDOBA",
  "PURÍSIMA, CORDOBA",
  "SAHAGÚN, CORDOBA",
  "SAN ANDRÉS SOTAVENTO, CORDOBA",
  "SAN ANTERO, CORDOBA",
  "SAN BERNARDO DEL VIENTO, CORDOBA",
  "SAN CARLOS, CORDOBA",
  "SAN PELAYO, CORDOBA",
  "TIERRALTA, CORDOBA",
  "VALENCIA, CORDOBA",
  "AGUA DE DIOS, CUNDINAMARCA",
  "ALBÁN, CUNDINAMARCA",
  "ANAPOIMA, CUNDINAMARCA",
  "ANOLAIMA, CUNDINAMARCA",
  "ARBELÁEZ, CUNDINAMARCA",
  "BELTRÁN, CUNDINAMARCA",
  "BITUIMA, CUNDINAMARCA",
  "BOJACÁ, CUNDINAMARCA",
  "CABRERA, CUNDINAMARCA",
  "CACHIPAY, CUNDINAMARCA",
  "CAJICÁ, CUNDINAMARCA",
  "CAPARRAPÍ, CUNDINAMARCA",
  "CAQUEZA, CUNDINAMARCA",
  "CARMEN DE CARUPA, CUNDINAMARCA",
  "CHAGUANÍ, CUNDINAMARCA",
  "CHÍA, CUNDINAMARCA",
  "CHIPAQUE, CUNDINAMARCA",
  "CHOACHÍ, CUNDINAMARCA",
  "CHOCONTÁ, CUNDINAMARCA",
  "COGUA, CUNDINAMARCA",
  "COTA, CUNDINAMARCA",
  "CUCUNUBÁ, CUNDINAMARCA",
  "EL COLEGIO, CUNDINAMARCA",
  "EL PEÑÓN, CUNDINAMARCA",
  "EL ROSAL, CUNDINAMARCA",
  "FACATATIVÁ, CUNDINAMARCA",
  "FOMEQUE, CUNDINAMARCA",
  "FOSCA, CUNDINAMARCA",
  "FUNZA, CUNDINAMARCA",
  "FÚQUENE, CUNDINAMARCA",
  "FUSAGASUGÁ, CUNDINAMARCA",
  "GACHALA, CUNDINAMARCA",
  "GACHANCIPÁ, CUNDINAMARCA",
  "GACHETA, CUNDINAMARCA",
  "GAMA, CUNDINAMARCA",
  "GIRARDOT, CUNDINAMARCA",
  "GRANADA, CUNDINAMARCA",
  "GUACHETÁ, CUNDINAMARCA",
  "GUADUAS, CUNDINAMARCA",
  "GUASCA, CUNDINAMARCA",
  "GUATAQUÍ, CUNDINAMARCA",
  "GUATAVITA, CUNDINAMARCA",
  "GUAYABAL DE SIQUIMA, CUNDINAMARCA",
  "GUAYABETAL, CUNDINAMARCA",
  "GUTIÉRREZ, CUNDINAMARCA",
  "JERUSALÉN, CUNDINAMARCA",
  "JUNÍN, CUNDINAMARCA",
  "LA CALERA, CUNDINAMARCA",
  "LA MESA, CUNDINAMARCA",
  "LA PALMA, CUNDINAMARCA",
  "LA PEÑA, CUNDINAMARCA",
  "LA VEGA, CUNDINAMARCA",
  "LENGUAZAQUE, CUNDINAMARCA",
  "MACHETA, CUNDINAMARCA",
  "MADRID, CUNDINAMARCA",
  "MANTA, CUNDINAMARCA",
  "MEDINA, CUNDINAMARCA",
  "MOSQUERA, CUNDINAMARCA",
  "NARIÑO, CUNDINAMARCA",
  "NEMOCoN, CUNDINAMARCA",
  "NILO, CUNDINAMARCA",
  "NIMAIMA, CUNDINAMARCA",
  "NOCAIMA, CUNDINAMARCA",
  "VENECIA, CUNDINAMARCA",
  "PACHO, CUNDINAMARCA",
  "PAIME, CUNDINAMARCA",
  "PANDI, CUNDINAMARCA",
  "PARATEBUENO, CUNDINAMARCA",
  "PASCA, CUNDINAMARCA",
  "PUERTO SALGAR, CUNDINAMARCA",
  "PULI, CUNDINAMARCA",
  "QUEBRADANEGRA, CUNDINAMARCA",
  "QUETAME, CUNDINAMARCA",
  "QUIPILE, CUNDINAMARCA",
  "APULO, CUNDINAMARCA",
  "RICAURTE, CUNDINAMARCA",
  "SAN ANTONIO DE TEQUENDAMA, CUNDINAMARCA",
  "SAN BERNARDO, CUNDINAMARCA",
  "SAN CAYETANO, CUNDINAMARCA",
  "SAN FRANCISCO, CUNDINAMARCA",
  "SAN JUAN DE RÍO SECO, CUNDINAMARCA",
  "SASAIMA, CUNDINAMARCA",
  "SESQUILÉ, CUNDINAMARCA",
  "SIBATÉ, CUNDINAMARCA",
  "SILVANIA, CUNDINAMARCA",
  "SIMIJACA, CUNDINAMARCA",
  "SOACHA, CUNDINAMARCA",
  "SOPÓ, CUNDINAMARCA",
  "SUBACHOQUE, CUNDINAMARCA",
  "SUESCA, CUNDINAMARCA",
  "SUPATÁ, CUNDINAMARCA",
  "SUSA, CUNDINAMARCA",
  "SUTATAUSA, CUNDINAMARCA",
  "TABIO, CUNDINAMARCA",
  "TAUSA, CUNDINAMARCA",
  "TENA, CUNDINAMARCA",
  "TENJO, CUNDINAMARCA",
  "TIBACUY, CUNDINAMARCA",
  "TIBIRITA, CUNDINAMARCA",
  "TOCAIMA, CUNDINAMARCA",
  "TOCANCIPÁ, CUNDINAMARCA",
  "TOPAIPI, CUNDINAMARCA",
  "UBALÁ, CUNDINAMARCA",
  "UBAQUE, CUNDINAMARCA",
  "UBATE, CUNDINAMARCA",
  "UNE, CUNDINAMARCA",
  "ÚTICA, CUNDINAMARCA",
  "VERGARA, CUNDINAMARCA",
  "VIANÍ, CUNDINAMARCA",
  "VILLAGOMEZ, CUNDINAMARCA",
  "VILLAPINZÓN, CUNDINAMARCA",
  "VILLETA, CUNDINAMARCA",
  "VIOTÁ, CUNDINAMARCA",
  "YACOPÍ, CUNDINAMARCA",
  "ZIPACoN, CUNDINAMARCA",
  "ZIPAQUIRÁ, CUNDINAMARCA",
  "QUIBDÓ, CHOCO",
  "ACANDÍ, CHOCO",
  "ALTO BAUDÓ, CHOCO",
  "ATRATO, CHOCO",
  "BAGADÓ, CHOCO",
  "BAHÍA SOLANO, CHOCO",
  "BAJO BAUDÓ, CHOCO",
  "BELÉN DE BAJIRA, CHOCO",
  "BOJAYA, CHOCO",
  "CANTON DE SAN PABLO, CHOCO",
  "CARMÉN DEL DARIÉN, CHOCO",
  "CERTEGUI, CHOCO",
  "CONDOTO, CHOCO",
  "EL CARMEN DE ATRATO, CHOCO",
  "El Litoral del San Juan, CHOCO",
  "ITSMINA, CHOCO",
  "JURADÓ, CHOCO",
  "LLORÓ, CHOCO",
  "MEDIO ATRATO, CHOCO",
  "MEDIO BAUDÓ, CHOCO",
  "MEDIO SAN JUAN, CHOCO",
  "NÓVITA, CHOCO",
  "NUQUÍ, CHOCO",
  "RÍO FRÍO, CHOCO",
  "RIO QUITO, CHOCO",
  "RIOSUCIO, CHOCO",
  "SAN JOSÉ DEL PALMAR, CHOCO",
  "SIPÍ, CHOCO",
  "TADÓ, CHOCO",
  "UNGUÍA, CHOCO",
  "UNION PANAMERICANA, CHOCO",
  "NEIVA, HUILA",
  "ACEVEDO, HUILA",
  "AGRADO, HUILA",
  "AIPE, HUILA",
  "ALGECIRAS, HUILA",
  "ALTAMIRA, HUILA",
  "BARAYA, HUILA",
  "CAMPOALEGRE, HUILA",
  "COLOMBIA, HUILA",
  "ELÍAS, HUILA",
  "GARZÓN, HUILA",
  "GIGANTE, HUILA",
  "GUADALUPE, HUILA",
  "HOBO, HUILA",
  "IQUIRA, HUILA",
  "ISNOS, HUILA",
  "LA ARGENTINA, HUILA",
  "LA PLATA, HUILA",
  "NÁTAGA, HUILA",
  "OPORAPA, HUILA",
  "PAICOL, HUILA",
  "PALERMO, HUILA",
  "PALESTINA, HUILA",
  "PITAL, HUILA",
  "PITALITO, HUILA",
  "RIVERA, HUILA",
  "SALADOBLANCO, HUILA",
  "SAN AGUSTÍN, HUILA",
  "SANTA MARÍA, HUILA",
  "SUAZA, HUILA",
  "TARQUI, HUILA",
  "TESALIA, HUILA",
  "TELLO, HUILA",
  "TERUEL, HUILA",
  "TIMANÁ, HUILA",
  "VILLAVIEJA, HUILA",
  "YAGUARÁ, HUILA",
  "RIOHACHA, LA GUAJIRA",
  "ALBANIA, LA GUAJIRA",
  "BARRANCAS, LA GUAJIRA",
  "DIBULLA, LA GUAJIRA",
  "DISTRACCION, LA GUAJIRA",
  "EL MOLINO, LA GUAJIRA",
  "FONSECA, LA GUAJIRA",
  "HATONUEVO, LA GUAJIRA",
  "LA JAGUA DEL PILAR, LA GUAJIRA",
  "MAICAO, LA GUAJIRA",
  "MANAURE, LA GUAJIRA",
  "SAN JUAN DEL CESAR, LA GUAJIRA",
  "URIBIA, LA GUAJIRA",
  "URUMITA, LA GUAJIRA",
  "VILLANUEVA, LA GUAJIRA",
  "SANTA MARTA, MAGDALENA",
  "ALGARROBO, MAGDALENA",
  "ARACATACA, MAGDALENA",
  "ARIGUANÍ, MAGDALENA",
  "CERRO SAN ANTONIO, MAGDALENA",
  "CHIBOLO, MAGDALENA",
  "CIÉNAGA, MAGDALENA",
  "CONCORDIA, MAGDALENA",
  "EL BANCO, MAGDALENA",
  "EL PIÑON, MAGDALENA",
  "EL RETEN, MAGDALENA",
  "FUNDACION, MAGDALENA",
  "GUAMAL, MAGDALENA",
  "NUEVA GRANADA, MAGDALENA",
  "PEDRAZA, MAGDALENA",
  "PIJIÑO DEL CARMEN, MAGDALENA",
  "PIVIJAY, MAGDALENA",
  "PLATO, MAGDALENA",
  "PUEBLO VIEJO, MAGDALENA",
  "REMOLINO, MAGDALENA",
  "SABANAS DE SAN ANGEL, MAGDALENA",
  "SALAMINA, MAGDALENA",
  "SAN SEBASTIAN DE BUENAVISTA, MAGDALENA",
  "SAN ZENON, MAGDALENA",
  "SANTA ANA, MAGDALENA",
  "SANTA BARBARA DE PINTO, MAGDALENA",
  "SITIONUEVO, MAGDALENA",
  "TENERIFE, MAGDALENA",
  "ZAPAYAN, MAGDALENA",
  "ZONA BANANERA, MAGDALENA",
  "VILLAVICENCIO, META",
  "ACACiAS, META",
  "BARRANCA DE UPIA, META",
  "CABUYARO, META",
  "CASTILLA LA NUEVA, META",
  "SAN LUIS DE CUBARRAL, META",
  "CUMARAL, META",
  "EL CALVARIO, META",
  "EL CASTILLO, META",
  "EL DORADO, META",
  "FUENTE DE ORO, META",
  "GRANADA, META",
  "GUAMAL, META",
  "MAPIRIPaN, META",
  "MESETAS, META",
  "LA MACARENA, META",
  "LA URIBE, META",
  "LEJANÍAS, META",
  "PUERTO CONCORDIA, META",
  "PUERTO GAITÁN, META",
  "PUERTO LoPEZ, META",
  "PUERTO LLERAS, META",
  "PUERTO RICO, META",
  "RESTREPO, META",
  "SAN CARLOS GUAROA, META",
  "SAN JUAN DE ARAMA, META",
  "SAN JUANITO, META",
  "SAN MARTÍN, META",
  "VISTA HERMOSA, META",
  "PASTO, NARIÑO",
  "ALBAN, NARIÑO",
  "ALDANA, NARIÑO",
  "ANCUYA, NARIÑO",
  "ARBOLEDA, NARIÑO",
  "BARBACOAS, NARIÑO",
  "BELEN, NARIÑO",
  "BUESACO, NARIÑO",
  "COLON, NARIÑO",
  "CONSACA, NARIÑO",
  "CONTADERO, NARIÑO",
  "CÓRDOBA, NARIÑO",
  "CUASPUD, NARIÑO",
  "CUMBAL, NARIÑO",
  "CUMBITARA, NARIÑO",
  "CHACHAGUI, NARIÑO",
  "EL CHARCO, NARIÑO",
  "EL PEÑOL, NARIÑO",
  "EL ROSARIO, NARIÑO",
  "El Tablon de Gomez, NARIÑO",
  "EL TAMBO, NARIÑO",
  "FUNES, NARIÑO",
  "GUACHUCAL, NARIÑO",
  "GUAITARILLA, NARIÑO",
  "GUALMATAN, NARIÑO",
  "ILES, NARIÑO",
  "IMUES, NARIÑO",
  "IPIALES, NARIÑO",
  "LA CRUZ, NARIÑO",
  "LA FLORIDA, NARIÑO",
  "LA LLANADA, NARIÑO",
  "LA TOLA, NARIÑO",
  "LA UNION, NARIÑO",
  "LEIVA, NARIÑO",
  "LINARES, NARIÑO",
  "LOS ANDES, NARIÑO",
  "Magui, NARIÑO",
  "MALLAMA, NARIÑO",
  "MOSQUERA, NARIÑO",
  "NARIÑO, NARIÑO",
  "OLAYA HERRERA, NARIÑO",
  "OSPINA, NARIÑO",
  "FRANCISCO PIZARRO, NARIÑO",
  "POLICARPA, NARIÑO",
  "POTOSÍ, NARIÑO",
  "PROVIDENCIA, NARIÑO",
  "PUERRES, NARIÑO",
  "PUPIALES, NARIÑO",
  "RICAURTE, NARIÑO",
  "ROBERTO PAYAN, NARIÑO",
  "SAMANIEGO, NARIÑO",
  "SANDONÁ, NARIÑO",
  "SAN BERNARDO, NARIÑO",
  "SAN LORENZO, NARIÑO",
  "SAN PABLO, NARIÑO",
  "SAN PEDRO DE CARTAGO, NARIÑO",
  "SANTA BARBARA, NARIÑO",
  "SANTA CRUZ, NARIÑO",
  "SAPUYES, NARIÑO",
  "TAMINANGO, NARIÑO",
  "TANGUA, NARIÑO",
  "TUMACO, NARIÑO",
  "TUQUERRES, NARIÑO",
  "YACUANQUER, NARIÑO",
  "CÚCUTA, NORTE DE SANTANDER",
  "ABREGO, NORTE DE SANTANDER",
  "ARBOLEDAS, NORTE DE SANTANDER",
  "BOCHALEMA, NORTE DE SANTANDER",
  "BUCARASICA, NORTE DE SANTANDER",
  "CÁCOTA, NORTE DE SANTANDER",
  "CACHIRÁ, NORTE DE SANTANDER",
  "CHINÁCOTA, NORTE DE SANTANDER",
  "CHITAGÁ, NORTE DE SANTANDER",
  "CONVENCIÓN, NORTE DE SANTANDER",
  "CUCUTILLA, NORTE DE SANTANDER",
  "DURANIA, NORTE DE SANTANDER",
  "EL CARMEN, NORTE DE SANTANDER",
  "EL TARRA, NORTE DE SANTANDER",
  "EL ZULIA, NORTE DE SANTANDER",
  "GRAMALOTE, NORTE DE SANTANDER",
  "HACARÍ, NORTE DE SANTANDER",
  "HERRÁN, NORTE DE SANTANDER",
  "LABATECA, NORTE DE SANTANDER",
  "LA ESPERANZA, NORTE DE SANTANDER",
  "LA PLAYA, NORTE DE SANTANDER",
  "LOS PATIOS, NORTE DE SANTANDER",
  "LOURDES, NORTE DE SANTANDER",
  "MUTISCUA, NORTE DE SANTANDER",
  "OCAÑA, NORTE DE SANTANDER",
  "PAMPLONA, NORTE DE SANTANDER",
  "PAMPLONITA, NORTE DE SANTANDER",
  "PUERTO SANTANDER, NORTE DE SANTANDER",
  "RAGONVALIA, NORTE DE SANTANDER",
  "SALAZAR, NORTE DE SANTANDER",
  "SAN CALIXTO, NORTE DE SANTANDER",
  "SAN CAYETANO, NORTE DE SANTANDER",
  "SANTIAGO, NORTE DE SANTANDER",
  "SARDINATA, NORTE DE SANTANDER",
  "SILOS, NORTE DE SANTANDER",
  "TEORAMA, NORTE DE SANTANDER",
  "TIBÚ, NORTE DE SANTANDER",
  "TOLEDO, NORTE DE SANTANDER",
  "VILLA CARO, NORTE DE SANTANDER",
  "VILLA DEL ROSARIO, NORTE DE SANTANDER",
  "ARMENIA, QUINDIO",
  "BUENAVISTA, QUINDIO",
  "CALARCA, QUINDIO",
  "CIRCASIA, QUINDIO",
  "CoRDOBA, QUINDIO",
  "FILANDIA, QUINDIO",
  "GeNOVA, QUINDIO",
  "LA TEBAIDA, QUINDIO",
  "Montengro, QUINDIO",
  "PIJAO, QUINDIO",
  "QUIMBAYA, QUINDIO",
  "SALENTO, QUINDIO",
  "PEREIRA, RISARALDA",
  "APÍA, RISARALDA",
  "BALBOA, RISARALDA",
  "BELÉN DE UMBRÍA, RISARALDA",
  "DOSQUEBRADAS, RISARALDA",
  "GUÁTICA, RISARALDA",
  "LA CELIA, RISARALDA",
  "LA VIRGINIA, RISARALDA",
  "MARSELLA, RISARALDA",
  "MISTRATÓ, RISARALDA",
  "PUEBLO RICO, RISARALDA",
  "QUINCHiA, RISARALDA",
  "SANTA ROSA DE CABAL, RISARALDA",
  "SANTUARIO, RISARALDA",
  "BUCARAMANGA, SANTANDER",
  "AGUADA, SANTANDER",
  "ALBANIA, SANTANDER",
  "ARATOCA, SANTANDER",
  "BARBOSA, SANTANDER",
  "BARICHARA, SANTANDER",
  "BARRANCABERMEJA, SANTANDER",
  "BETULIA, SANTANDER",
  "BOLÍVAR, SANTANDER",
  "CABRERA, SANTANDER",
  "CALIFORNIA, SANTANDER",
  "CAPITANEJO, SANTANDER",
  "CARCASÍ, SANTANDER",
  "CEPITÁ, SANTANDER",
  "CERRITO, SANTANDER",
  "CHARALÁ, SANTANDER",
  "CHARTA, SANTANDER",
  "CHIMA, SANTANDER",
  "CHIPATÁ, SANTANDER",
  "CIMITARRA, SANTANDER",
  "CONCEPCIÓN, SANTANDER",
  "CONFINES, SANTANDER",
  "CONTRATACIÓN, SANTANDER",
  "COROMORO, SANTANDER",
  "CURITÍ, SANTANDER",
  "EL CARMEN DE CHUCURÍ, SANTANDER",
  "EL GUACAMAYO, SANTANDER",
  "EL PEÑÓN, SANTANDER",
  "EL PLAYÓN, SANTANDER",
  "ENCINO, SANTANDER",
  "ENCISO, SANTANDER",
  "FLORIÁN, SANTANDER",
  "FLORIDABLANCA, SANTANDER",
  "GALÁN, SANTANDER",
  "GAMBITA, SANTANDER",
  "GIRÓN, SANTANDER",
  "GUACA, SANTANDER",
  "GUADALUPE, SANTANDER",
  "GUAPOTÁ, SANTANDER",
  "GUAVATÁ, SANTANDER",
  "GuEPSA, SANTANDER",
  "HATO, SANTANDER",
  "JESÚS MARÍA, SANTANDER",
  "JORDÁN, SANTANDER",
  "LA BELLEZA, SANTANDER",
  "LANDÁZURI, SANTANDER",
  "LA PAZ, SANTANDER",
  "LEBRÍJA, SANTANDER",
  "LOS SANTOS, SANTANDER",
  "MACARAVITA, SANTANDER",
  "MÁLAGA, SANTANDER",
  "MATANZA, SANTANDER",
  "MOGOTES, SANTANDER",
  "MOLAGAVITA, SANTANDER",
  "OCAMONTE, SANTANDER",
  "OIBA, SANTANDER",
  "ONZAGA, SANTANDER",
  "PALMAR, SANTANDER",
  "PALMAS DEL SOCORRO, SANTANDER",
  "PÁRAMO, SANTANDER",
  "PIEDECUESTA, SANTANDER",
  "PINCHOTE, SANTANDER",
  "PUENTE NACIONAL, SANTANDER",
  "PUERTO PARRA, SANTANDER",
  "PUERTO WILCHES, SANTANDER",
  "RIONEGRO, SANTANDER",
  "SABANA DE TORRES, SANTANDER",
  "SAN ANDRÉS, SANTANDER",
  "SAN BENITO, SANTANDER",
  "SAN GIL, SANTANDER",
  "SAN JOAQUÍN, SANTANDER",
  "SAN JOSÉ DE MIRANDA, SANTANDER",
  "SAN MIGUEL, SANTANDER",
  "SAN VICENTE DE CHUCURÍ, SANTANDER",
  "SANTA BÁRBARA, SANTANDER",
  "SANTA HELENA DEL OPÓN, SANTANDER",
  "SIMACOTA, SANTANDER",
  "SOCORRO, SANTANDER",
  "SUAITA, SANTANDER",
  "SUCRE, SANTANDER",
  "SURATA, SANTANDER",
  "TONA, SANTANDER",
  "VALLE DE SAN JOSÉ, SANTANDER",
  "VÉLEZ, SANTANDER",
  "VETAS, SANTANDER",
  "VILLANUEVA, SANTANDER",
  "ZAPATOCA, SANTANDER",
  "SINCELEJO, SUCRE",
  "BUENAVISTA, SUCRE",
  "CAIMITO, SUCRE",
  "COLOSO, SUCRE",
  "COROZAL, SUCRE",
  "COVEÑAS, SUCRE",
  "CHALÁN, SUCRE",
  "EL ROBLE, SUCRE",
  "GALERAS, SUCRE",
  "GUARANDA, SUCRE",
  "LA UNIÓN, SUCRE",
  "LOS PALMITOS, SUCRE",
  "MAJAGUAL, SUCRE",
  "MORROA, SUCRE",
  "OVEJAS, SUCRE",
  "PALMITO, SUCRE",
  "SAMPUÉS, SUCRE",
  "SAN BENITO ABAD, SUCRE",
  "SAN JUAN BETULIA, SUCRE",
  "SAN MARCOS, SUCRE",
  "SAN ONOFRE, SUCRE",
  "SAN PEDRO, SUCRE",
  "SINCÉ, SUCRE",
  "SUCRE, SUCRE",
  "SANTIAGO DE TOLÚ, SUCRE",
  "TOLÚ VIEJO, SUCRE",
  "IBAGUe, TOLIMA",
  "ALPUJARRA, TOLIMA",
  "ALVARADO, TOLIMA",
  "AMBALEMA, TOLIMA",
  "ANZOÁTEGUI, TOLIMA",
  "ARMERO, TOLIMA",
  "ATACO, TOLIMA",
  "CAJAMARCA, TOLIMA",
  "CARMEN DE APICALÁ, TOLIMA",
  "CASABIANCA, TOLIMA",
  "CHAPARRAL, TOLIMA",
  "COELLO, TOLIMA",
  "COYAIMA, TOLIMA",
  "CUNDAY, TOLIMA",
  "DOLORES, TOLIMA",
  "ESPINAL, TOLIMA",
  "FALAN, TOLIMA",
  "FLANDES, TOLIMA",
  "FRESNO, TOLIMA",
  "GUAMO, TOLIMA",
  "HERVEO, TOLIMA",
  "HONDA, TOLIMA",
  "ICONONZO, TOLIMA",
  "LeRIDA, TOLIMA",
  "LiBANO, TOLIMA",
  "MARIQUITA, TOLIMA",
  "MELGAR, TOLIMA",
  "MURILLO, TOLIMA",
  "NATAGAIMA, TOLIMA",
  "ORTEGA, TOLIMA",
  "PALOCABILDO, TOLIMA",
  "PIEDRAS, TOLIMA",
  "PLANADAS, TOLIMA",
  "PRADO, TOLIMA",
  "PURIFICACIÓN, TOLIMA",
  "RIOBLANCO, TOLIMA",
  "RONCESVALLES, TOLIMA",
  "ROVIRA, TOLIMA",
  "SALDAÑA, TOLIMA",
  "SAN ANTONIO, TOLIMA",
  "SAN LUIS, TOLIMA",
  "SANTA ISABEL, TOLIMA",
  "SUÁREZ, TOLIMA",
  "VALLE DE SAN JUAN, TOLIMA",
  "VENADILLO, TOLIMA",
  "VILLAHERMOSA, TOLIMA",
  "VILLARRICA, TOLIMA",
  "CALI, VALLE DEL CAUCA",
  "ALCALa, VALLE DEL CAUCA",
  "ANDALUCÍA, VALLE DEL CAUCA",
  "ANSERMANUEVO, VALLE DEL CAUCA",
  "ARGELIA, VALLE DEL CAUCA",
  "BOLÍVAR, VALLE DEL CAUCA",
  "BUENAVENTURA, VALLE DEL CAUCA",
  "BUGA, VALLE DEL CAUCA",
  "BUGALAGRANDE, VALLE DEL CAUCA",
  "CAICEDONIA, VALLE DEL CAUCA",
  "CALIMA, VALLE DEL CAUCA",
  "CANDELARIA, VALLE DEL CAUCA",
  "CARTAGO, VALLE DEL CAUCA",
  "DAGUA, VALLE DEL CAUCA",
  "EL ÁGUILA, VALLE DEL CAUCA",
  "EL CAIRO, VALLE DEL CAUCA",
  "EL CERRITO, VALLE DEL CAUCA",
  "EL DOVIO, VALLE DEL CAUCA",
  "FLORIDA, VALLE DEL CAUCA",
  "GINEBRA, VALLE DEL CAUCA",
  "GUACARÍ, VALLE DEL CAUCA",
  "JAMUNDÍ, VALLE DEL CAUCA",
  "LA CUMBRE, VALLE DEL CAUCA",
  "LA UNIÓN, VALLE DEL CAUCA",
  "LA VICTORIA, VALLE DEL CAUCA",
  "OBANDO, VALLE DEL CAUCA",
  "PALMIRA, VALLE DEL CAUCA",
  "PRADERA, VALLE DEL CAUCA",
  "RESTREPO, VALLE DEL CAUCA",
  "RIOFRIO, VALLE DEL CAUCA",
  "ROLDANILLO, VALLE DEL CAUCA",
  "SAN PEDRO, VALLE DEL CAUCA",
  "SEVILLA, VALLE DEL CAUCA",
  "TORO, VALLE DEL CAUCA",
  "TRUJILLO, VALLE DEL CAUCA",
  "TULUÁ, VALLE DEL CAUCA",
  "ULLOA, VALLE DEL CAUCA",
  "VERSALLES, VALLE DEL CAUCA",
  "VIJES, VALLE DEL CAUCA",
  "YOTOCO, VALLE DEL CAUCA",
  "YUMBO, VALLE DEL CAUCA",
  "ZARZAL, VALLE DEL CAUCA",
  "ARAUCA, ARAUCA",
  "ARAUQUITA, ARAUCA",
  "CRAVO NORTE, ARAUCA",
  "FORTUL, ARAUCA",
  "PUERTO RONDÓN, ARAUCA",
  "SARAVENA, ARAUCA",
  "TAME, ARAUCA",
  "YOPAL, CASANARE",
  "AGUAZUL, CASANARE",
  "CHAMEZA, CASANARE",
  "HATO COROZAL, CASANARE",
  "LA SALINA, CASANARE",
  "MANÍ, CASANARE",
  "MONTERREY, CASANARE",
  "NUNCHÍA, CASANARE",
  "OROCUÉ, CASANARE",
  "PAZ DE ARIPORO, CASANARE",
  "PORE, CASANARE",
  "RECETOR, CASANARE",
  "SABANALARGA, CASANARE",
  "SÁCAMA, CASANARE",
  "SAN LUIS DE PALENQUE, CASANARE",
  "TÁMARA, CASANARE",
  "TAURAMENA, CASANARE",
  "TRINIDAD, CASANARE",
  "VILLANUEVA, CASANARE",
  "MOCOA, PUTUMAYO",
  "COLÓN, PUTUMAYO",
  "ORITO, PUTUMAYO",
  "PUERTO ASIS, PUTUMAYO",
  "PUERTO CAICEDO, PUTUMAYO",
  "PUERTO GUZMAN, PUTUMAYO",
  "PUERTO LEGUIZAMO, PUTUMAYO",
  "SIBUNDOY, PUTUMAYO",
  "SAN FRANCISCO, PUTUMAYO",
  "SAN MIGUEL, PUTUMAYO",
  "SANTIAGO, PUTUMAYO",
  "VALLE DEL GUAMUEZ, PUTUMAYO",
  "VILLA GARZON, PUTUMAYO",
  "SAN ANDReS, ARCHIPIELAGO DE SAN ANDRES",
  "PROVIDENCIA Y SANTA CATALINA, ARCHIPIELAGO DE SAN ANDRES",
  "LETICIA, AMAZONAS",
  "EL ENCANTO, AMAZONAS",
  "LA CHORRERA, AMAZONAS",
  "LA PEDRERA, AMAZONAS",
  "LA VICTORIA, AMAZONAS",
  "MIRITI, PARANÁ, AMAZONAS",
  "PUERTO ALEGRIA, AMAZONAS",
  "PUERTO ARICA, AMAZONAS",
  "PUERTO NARIÑO, AMAZONAS",
  "PUERTO SANTANDER, AMAZONAS",
  "TARAPACÁ, AMAZONAS",
  "INÍRIDA, GUAINIA",
  "BARRANCO MINA, GUAINIA",
  "MAPIRIPAN, GUAINIA",
  "SAN FELIPE, GUAINIA",
  "PUERTO COLOMBIA, GUAINIA",
  "LA GUADALUPE, GUAINIA",
  "CACAHUAL, GUAINIA",
  "PANA PANA, GUAINIA",
  "MORICHAL, GUAINIA",
  "SAN JOSÉ DEL GUAVIARE, GUAVIARE",
  "CALAMAR, GUAVIARE",
  "EL RETORNO, GUAVIARE",
  "MIRAFLORES, GUAVIARE",
  "MITÚ, VAUPES",
  "CARURU, VAUPES",
  "PACOA, VAUPES",
  "TARAIRA, VAUPES",
  "PAPUNAHUA, VAUPES",
  "YAVARATÉ, VAUPES",
  "PUERTO CARREÑO, VICHADA",
  "LA PRIMAVERA, VICHADA",
  "SANTA ROSALÍA, VICHADA",
  "CUMARIBO, VICHADA"
]